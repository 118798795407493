import React from 'react';
import { request } from 'graphql-request';
import moment from 'moment';
import { Button } from 'rsuite'
import { Loader } from 'rsuite';
import './Styles/Articles.css';
import Footer from './Footer';
import { Pagination } from 'rsuite';
import { Link } from 'react-router-dom';

const Articles = () => {
    const [posts, setPosts] = React.useState(null);
    const [activePage, setActivePage] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState(null);
    const [currentPagePosts, setCurrentPagePosts] = React.useState(null);
    React.useEffect(() => {
        const fetchPosts = async () => {
            const { postsConnection } = await request(
                'https://api-ap-south-1.graphcms.com/v2/cl1ipxxok67rl01z61a4r4ndg/master',
                `
            {
              postsConnection(
                orderBy: date_DESC
              ) {
                edges {
                  node {
                    id
                    title
                    slug
                    date
                    excerpt
                    author {
                        name
                        authorSlug
                        picture {
                            url
                        }
                    }
                    featuredImage {
                        url
                    }
                  }
                }
              }
            }
        `
            );


            setPosts(postsConnection.edges);
            setCurrentPagePosts(postsConnection.edges.slice(0, 5));
            setTotalCount(postsConnection.edges.length);
            setActivePage(1);
        };
        fetchPosts();
    }, []);

    const handleNext = (page) => {
        const endPoint = page * 5;
        const startPoint = endPoint - 5;
        setCurrentPagePosts(posts.slice(startPoint, endPoint));
        setActivePage(page);
    };


    if (!currentPagePosts) return (<Loader center size="lg" content="loading" />);
    return (
        <>
            <div className='article-container'>{
                currentPagePosts.map((post, id) => (
                    <div key={id} className='articles'>
                        <div id="artcile-image-div">
                            <a href={`/posts/${post.node.slug}`}>
                                <img hrer="" id="artcile-image" src={post.node?.featuredImage?.url} alt="test" />
                            </a>
                        </div>
                        <div id="article-data">

                            <h5 id="article-title">{post.node.title}</h5>
                            <div id="article-date">{moment(post.node.date).format('MMM DD, YYYY')}</div>

                            <div id="article-author">
                                <img id="author-pic2" src={post.node?.author?.picture?.url} height='30px' width='30px' alt="test" />
                                <Link id="link" to={`/author/${post.node.author.authorSlug}`}><span id="author">By {post.node.author.name}</span></Link>
                            </div>

                            <div id="article-excerpt">{post.node.excerpt}</div>
                            <Button href={`/posts/${post.node.slug}`} appearance="ghost">Read More.....</Button>
                        </div>

                    </div>
                ))}

            </div>
            <div className='pager'>
                <Pagination
                    prev
                    last
                    next
                    first
                    size="lg"
                    total={totalCount}
                    limit={5}
                    activePage={activePage}
                    onChangePage={handleNext}
                />
            </div>
            <div>
                <Footer />
            </div>
        </>
    )
}

export default Articles