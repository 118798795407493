import React from 'react';
import { Loader } from 'rsuite';
import { request } from 'graphql-request';
import { RichText } from '@graphcms/rich-text-react-renderer';
import Footer from './Footer';
import '../components/Styles/Videos.css';
import { Pagination } from 'rsuite';


const Videos = () => {
    const [videos, setVideos] = React.useState(null);
    const [activePage, setActivePage] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState(null);
    const [currentPageVideos, setCurrentPageVideos] = React.useState(null);
    React.useEffect(() => {
        const fetchVideos = async () => {
            const { asifVideosConnection } = await request(
                'https://api-ap-south-1.graphcms.com/v2/cl1ipxxok67rl01z61a4r4ndg/master',
                `
            {
                asifVideosConnection (
                    orderBy: createdAt_DESC
                ) {
                  edges {
                    node {
                    createdAt
                    tittle
                    channelLink
                    channel
                      video {
                        raw
                      }
                    }
                  }
                }
              }
    `
            );
            setVideos(asifVideosConnection.edges);
            setCurrentPageVideos(asifVideosConnection?.edges?.slice(0, 9));
            setTotalCount(asifVideosConnection?.edges?.length)
            setActivePage(1)
        };

        fetchVideos()
    }, []);

    const handleNext = (page) => {
        const endPoint = page * 9;
        const startPoint = endPoint - 9;
        setCurrentPageVideos(videos.slice(startPoint, endPoint));
        setActivePage(page);
    };
    if (!currentPageVideos) return (<Loader center size="lg" content="loading" />);
    return (
        <>
            <div className='videos-container'>
                <div className='videos-grid-2'>
                    {
                        currentPageVideos.map((video) => (
                            <div className='videos-grid-item-2'>
                                <RichText content={video?.node?.video?.raw?.children} />
                                <div className='videos-info video-title'>
                                    <div>{video?.node?.tittle}</div>
                                    <div>Channel: <a href={video?.node?.channelLink}>{video?.node?.channel}</a></div>
                                </div>

                            </div>
                        ))
                    }
                </div>
            </div>
            <div className='pager'>
                <Pagination
                    prev
                    last
                    next
                    first
                    size="lg"
                    total={totalCount}
                    limit={9}
                    activePage={activePage}
                    onChangePage={handleNext}
                />
            </div>
            <Footer />
        </>
    );
};

export default Videos;