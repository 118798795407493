import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import "./Styles/ContactInfo.css"; // import CSS file with styles

function ContactInfo() {
    return (
        <div className="contact-info">
            <div className="contact-item">
                <FontAwesomeIcon icon={faEnvelope} className="icon" />
                <a href="mailto:example@example.com">asif@fpnaprofessionals.com</a>
            </div>
            <div className="contact-item">
                <FontAwesomeIcon icon={faPhoneAlt} className="icon" />
                <a href="tel:+1234567890">+91 8097379663</a>
            </div>
            <div className="contact-item">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                <span>Delta Gardens, Siddhivinayak Road, Mira Road East, Thane 401107, Maharashta, India</span>
            </div>
        </div>
    );
}

export default ContactInfo;
