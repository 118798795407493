import React from "react";
import { Navbar, Nav, Slider } from 'rsuite';
import HomeIcon from '@rsuite/icons/legacy/Home';
import ResponsiveNav from '@rsuite/responsive-nav';
import '../../node_modules/rsuite/dist/rsuite.css';
import { Button } from 'rsuite';
import logo from '../data/images/logo1.jpg'
import './Styles/navbar.css'

const CustomNavbar = () => {
    const [isNavExpanded, setIsNavExpanded] = React.useState(false);
    return (
        <>
            <div className="header-wrap">
                <div className="banner-item"> <img width="450px" src={logo} /></div>
                {/* <div className="site-slogan"><h6>The essential guide to your FP&A journey</h6></div> */}
            </div>

            <div className="nav-bar-container">
                <div className='nav-bar-main'>

                    <nav className="navigation">
                        <button
                            className="hamburger"
                            onClick={() => {
                                setIsNavExpanded(!isNavExpanded);
                            }}
                        >
                            {/* icon from Heroicons.com */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="white"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                        <div
                            className={
                                isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                            }
                        >
                            <ul>
                                <li>
                                    <a href="/home">Home</a>
                                </li>
                                <li>
                                    <a href="/articles">Articles</a>
                                </li>
                                <li>
                                    <a href="/courses">Courses</a>
                                </li>
                                <li>
                                    <a href="/certifications">Certifications</a>
                                </li>
                                <li>
                                    <a href="/books">Books</a>
                                </li>
                                <li>
                                    <a href="/videos">Videos</a>
                                </li>
                                <li>
                                    <a href="/contributors">Contributors</a>
                                </li>
                                <li>
                                    <a href="/about">About</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>

            </div>
            <div className="ad-img">
                <img src={require('../data/images/ad-gif1.gif')} onClick={() => { window.open("https://www.amazon.in/All-About-FP-ASIF-MASANI/dp/939363596X/ref=sr_1_1?crid=ZRGSH22FVZ8K&keywords=Asif+Masani&qid=1667700466&qu=eyJxc2MiOiIxLjY5IiwicXNhIjoiMC4wMCIsInFzcCI6IjAuMDAifQ%3D%3D&sprefix=asif+masani%2Caps%2C213&sr=8-1") }} />
                {/* <div id="btn">
                    <a href="https://sendfox.com/asifmasani" target="_blank">
                        <Button className='btn-home' appearance="ghost">Subscribe to Newsletter</Button>
                    </a>
                    <a href="https://topmate.io/asif_masani" target="_blank">
                        <Button className='btn-home' color="#419bf5" appearance="primary">Schedule 1:1 with Asif</Button>
                    </a>
                </div> */}
            </div>


        </>
    );
};

export default CustomNavbar;
