import React from 'react';
import { Link } from 'react-router-dom';
import { request } from 'graphql-request';
import './Styles/Home.css'
import '../../node_modules/rsuite/dist/rsuite.css';
import moment from 'moment';
import { Loader } from 'rsuite';
import { RichText } from '@graphcms/rich-text-react-renderer';
import Footer from './Footer';
const Home = () => {
    const [posts, setPosts] = React.useState(null);
    const [videos, setVideos] = React.useState(null);
    React.useEffect(() => {
        const fetchPosts = async () => {
            const { postsConnection } = await request(
                'https://api-ap-south-1.graphcms.com/v2/cl1ipxxok67rl01z61a4r4ndg/master',
                `
            {
              postsConnection(
                orderBy: date_DESC
                first: 10
              ) {
                edges {
                  node {
                    id
                    title
                    slug
                    date
                    author {
                        name
                        title
                        authorSlug
                        id
                        picture {
                            url
                        }
                    }
                    featuredImage {
                        url
                    }
                  }
                }
              }
            }
        `
            );

            setPosts(postsConnection.edges);
        };
        const fetchVideos = async () => {
            const { asifVideosConnection } = await request(
                'https://api-ap-south-1.graphcms.com/v2/cl1ipxxok67rl01z61a4r4ndg/master',
                `
                {
                    asifVideosConnection (
                        orderBy: createdAt_DESC
                        first: 6
                    ) {
                      edges {
                        node {
                        tittle
                        channelLink
                        channel
                        createdAt
                          video {
                            raw
                          }
                        }
                      }
                    }
                  }
        `
            );

            setVideos(asifVideosConnection.edges);
        };
        fetchPosts();
        fetchVideos();
    }, []);

    if (!posts || !videos) return (<Loader center size="lg" content="loading" />);

    return (
        <>
            <div>

                <div className="centered">

                    {/* <div><br /> <h4 className='divider'> Latest Articles</h4><hr /></div> */}
                    <h2 className='divider'>Latest Articles</h2>
                    <img className="dip" src={require('../data/images/ad-2.png')} onClick={() => { window.open("http://shrsl.com/2acg5-2vbe-1b7ye") }} />
                    <section className="cards">

                        {posts.map((post) => (
                            <div className="card" key={post.node.id}>
                                <div className="andar">
                                    <div>
                                        <a href={`/posts/${post.node.slug}`}>
                                            <img id="feature-pic" src={post.node?.featuredImage?.url} alt="test" />
                                        </a>
                                    </div>
                                    <div className="content" >
                                        <Link id="link" to={`/posts/${post.node.slug}`}>{post.node.title}</Link>
                                        <div>
                                            <span id="date">{moment(post.node.date).format('MMM DD, YYYY')}</span>

                                        </div>
                                        <div>
                                            <Link id="link" to={`/author/${post.node.author.authorSlug}`}><span id="author">By {post.node.author.name}</span></Link>
                                            <img id="author-pic" src={post.node?.author?.picture?.url} height='30px' width='30px' alt="test" />
                                            <br />
                                            {/* <span>- {post.node.author.title}</span> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </section>

                    <div> <h2 className='divider'>Latest Videos</h2></div>
                    <div className='grid-2'>
                        {
                            videos.map((video) => (
                                <div className='grid-item-2'>
                                    <RichText content={video?.node?.video?.raw?.children} />
                                    <div className='videos-info video-title'>
                                        <div>{video?.node?.tittle}</div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div >
            <Footer />
        </>

    );


};
export default Home;