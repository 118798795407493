import React from "react";
import "./Styles/ourcontributors.css"; // import CSS file with styles
import { Loader } from 'rsuite';
import { request } from 'graphql-request';
import Footer from './Footer';

// const images = [
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 1",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 2",
//     description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 3",
//     description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 1",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 2",
//     description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 3",
//     description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 1",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 2",
//     description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 3",
//     description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 1",
//     description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 2",
//     description: "Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
//   },
//   {
//     src: "https://via.placeholder.com/300",
//     title: "Image 3",
//     description: "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
//   }
//   // Add more images as needed
// ];

function OurContributors() {
  const [authors, setAuthors] = React.useState(null);
  React.useEffect(() => {
    const fetchAuthors = async () => {
      const { authorsConnection } = await request(
        'https://api-ap-south-1.graphcms.com/v2/cl1ipxxok67rl01z61a4r4ndg/master',
        `
        {
          authorsConnection(orderBy:name_ASC) {
            edges {
              node {
                id
                authorSlug
                name
                picture {
                  url
                }
              }
            }
          }
        }
    `
      );

      console.log(authorsConnection);
      setAuthors(authorsConnection.edges);
      console.log(authors);
    };
    fetchAuthors();
  }, []);

  if (!authors) return (<Loader center size="lg" content="loading" />);

  return (
    <><div className="cont-container"><div>
      <h2 className='divider2'>Authors</h2>
    </div><div className="image-grid">

        {authors.map((author) => (
          <a href={`/author/${author.node.authorSlug}`}>
            <div key={author.node.id} className="image-card">
              <div>
                <img src={author?.node.picture?.url} alt={author.node.name} />
              </div>
              <h4>{author.node.name}</h4>
              {/* <p>{image.description}</p> */}
            </div>
          </a>
        ))}
      </div></div><Footer /></>
  );
}

export default OurContributors;
