import React from 'react';
import { Loader } from 'rsuite';
import Footer from './Footer';
import './Styles/books.css';
import { request } from 'graphql-request';
import { Pagination } from 'rsuite';


const Books = () => {
    const [activePage, setActivePage] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState(null);
    const [currentPageBooks, setCurrentPageBooks] = React.useState(null);
    const [books, setBooks] = React.useState(null);
    React.useEffect(() => {
        const fetchBooks = async () => {
            const { bookConnection } = await request(
                'https://api-ap-south-1.graphcms.com/v2/cl1ipxxok67rl01z61a4r4ndg/master',
                `
                {
                    bookConnection {
                        edges {
                          node {
                            tittle,
                            subtittle,
                            price,
                            image {
                              url
                            },
                            url
                          }
                        }
                      }
            }
        `
            );
            setBooks(bookConnection?.edges);
            setCurrentPageBooks(bookConnection?.edges?.slice(0, 8));
            setTotalCount(bookConnection?.edges?.length)
            setActivePage(1)
        };
        fetchBooks();
    }, []);
    if (!currentPageBooks) return (<Loader center size="lg" content="loading" />);

    const handleNext = (page) => {
        const endPoint = page * 8;
        const startPoint = endPoint - 8;
        setCurrentPageBooks(books.slice(startPoint, endPoint));
        setActivePage(page);
    };

    return (
        <><div className="book-container">
            {currentPageBooks.map((val, id) => (
                <div key={id} className="card-child-books" onClick={() => { window.open(val.node.url, "_blank"); }}>
                    <div className="image-container-books"><img className='card-image-books' src={val.node.image.url} />
                    </div>
                    <div className="card-title-books">{val.node.tittle}</div>
                    <div className='card-subtitle-books'>{val.node.subtittle}</div>
                    <div className='extra-info-books'>
                        <div className='course-price-books'>{val.node.price}</div>
                    </div>
                </div>
            ))}

        </div>
            <div className='pager'>
                <Pagination
                    prev
                    last
                    next
                    first
                    size="lg"
                    total={totalCount}
                    limit={8}
                    activePage={activePage}
                    onChangePage={handleNext}
                />
            </div>
            <Footer /></>
    )
}

export default Books