import React from 'react';
import { Loader } from 'rsuite';
import Footer from './Footer';
import './Styles/courses.css';
import { request } from 'graphql-request';
import { Pagination } from 'rsuite';


const Courses = () => {
    const [activePage, setActivePage] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState(null);
    const [currentPageCourses, setCurrentPageCourses] = React.useState(null);
    const [courses, setCourses] = React.useState(null);
    React.useEffect(() => {
        const fetchCourses = async () => {
            const { coursesList } = await request(
                'https://api-ap-south-1.graphcms.com/v2/cl1ipxxok67rl01z61a4r4ndg/master',
                `
                {
                    coursesList (orderBy: no_ASC) {
                      id
                      text
                      title
                      link
                      authorImg {
                        url
                      }
                      image {
                        url
                      }
                      name
                      price
                    }
                  }
        `
            );

            setCourses(coursesList);
            setCurrentPageCourses(coursesList?.slice(0, 9));
            setTotalCount(coursesList.length)
            setActivePage(1)
        };
        fetchCourses();
    }, []);

    const handleNext = (page) => {
        const endPoint = page * 9;
        const startPoint = endPoint - 9;
        setCurrentPageCourses(courses.slice(startPoint, endPoint));
        setActivePage(page);
    };
    if (!currentPageCourses) return (<Loader center size="lg" content="loading" />);

    return (
        <><div className="card-courses">
            {currentPageCourses.map((val, id) => (
                <div key={id} className="card-child-courses" onClick={() => { window.open(val.link, "_blank"); }}>
                    <div className="image-container"><img className='card-image-courses' src={val.image.url} />
                    </div>
                    <div className="card-title-courses">{val.title}</div>
                    <div className='card-subtitle-courses'>{val.text}</div>
                    <div className='extra-info-courses'>
                        <div>
                            {val.authorImg.url && <img className="img-circle-courses" src={val.authorImg.url} />}
                            <span className='small-author-name-courses'>{val.name}</span>
                        </div>
                        <div className='course-price-courses'>{val.price}</div>
                    </div>
                </div>
            ))}


        </div>
            <div className='pager'>
                <Pagination
                    prev
                    last
                    next
                    first
                    size="lg"
                    total={totalCount}
                    limit={9}
                    activePage={activePage}
                    onChangePage={handleNext}
                />
            </div><Footer /></>
    )
}

export default Courses