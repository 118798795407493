import React from 'react'
import { useParams } from 'react-router-dom';
import { GraphQLClient } from 'graphql-request';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { Loader } from 'rsuite';
import '../components/Styles/post.css';
import Footer from './Footer';

const Post = () => {
  const { slug } = useParams();
  const [post, setpost] = React.useState(null);
  const client = new GraphQLClient('https://api-ap-south-1.graphcms.com/v2/cl1ipxxok67rl01z61a4r4ndg/master')
  const query = ` query GetPostDetails($slug : String!) {
        post(where: {slug: $slug}) {
          title
          excerpt
          featuredImage {
            url
          }
          author{
            name
            biography
            picture {
              url
            }
          }
          createdAt
          date
          slug
          content {
            raw
          }
          categories {
            name
            slug
          }
        }
      }`

  const variables = {
    slug: slug,
  }
  React.useEffect(() => {
    const fetchPost = async () => {
      const { post } = await client.request(query, variables);
      setpost(post);
    };
    fetchPost();
  }, []);
  console.log();
  if (!post) return (<Loader center size="lg" content="loading" />);

  return (
    <>
      <div className='post-container'>
        <div className='child-container'>
          <div id='image-title'>
            <img src={post?.featuredImage?.url} id='title-image' />
            <h3>{post.title}</h3>
          </div>

          <RichText content={post?.content?.raw?.children} />
        </div>

      </div>
      <Footer />
    </>
  );
};
export default Post;
