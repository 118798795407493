import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CustomNavbar from './components/CustomNavBar';
import Home from './components/Home';
import Post from './components/Post'
import Articles from './components/Articles';
import About from './components/About';
import Videos from './components/Videos';
import Courses from './components/Courses';
import Author from './components/Author';
import Certifications from './components/Certifications';
import OurContributors from './components/OurContributors';
import Books from './components/Books';

function App() {
  const [activeKey, setActiveKey] = React.useState(null);
  return (
    <div className="App">
      <CustomNavbar appearance="inverse" activeKey={activeKey} onSelect={setActiveKey} />
      <Router>
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/videos">
            <Videos />
          </Route>
          <Route path="/articles">
            <Articles />
          </Route>
          <Route path="/courses">
            <Courses />
          </Route>
          <Route path="/posts/:slug">
            <Post />
          </Route>
          <Route path="/author/:slug">
            <Author />
          </Route>
          <Route path="/certifications">
            <Certifications />
          </Route>
          <Route path="/contributors">
            <OurContributors />
          </Route>
          <Route path="/books">
            <Books />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
