import React from 'react';
import { GraphQLClient } from 'graphql-request';
import { useParams } from 'react-router-dom';
import { Loader } from 'rsuite';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import moment from 'moment';
import { Button } from 'rsuite';
import { Pagination } from 'rsuite';;


const Author = () => {
    const { slug } = useParams();
    const [author, setAuthor] = React.useState(null);
    const [posts, setPosts] = React.useState(null);
    const [activePage, setActivePage] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState(null);
    const [currentPagePosts, setCurrentPagePosts] = React.useState(null);
    const client = new GraphQLClient('https://api-ap-south-1.graphcms.com/v2/cl1ipxxok67rl01z61a4r4ndg/master')
    const query = ` query GetAuthor($authorSlug : String!) {
        author(where: {authorSlug: $authorSlug}) {
            id
            name
            biography
            title
            picture {
                url
            }
            about {
                raw
            }
            posts(orderBy: date_DESC) {
                id
                title
                slug
                date
                excerpt
                author {
                    authorSlug
                    name
                    id
                    picture {
                        url
                    }
                }
                featuredImage {
                    url
                }
              }
        }
      }`
    const variables = {
        authorSlug: slug,
    }
    React.useEffect(() => {
        const fetchAuthor = async () => {
            const { author } = await client.request(query, variables);
            setAuthor(author);
            setPosts(author.posts);
            setCurrentPagePosts(author.posts.slice(0, 5));
            setTotalCount(author.posts.length);
            setActivePage(1);
        };
        fetchAuthor();

    }, []);
    const handleNext = (page) => {
        console.log(activePage);
        console.log(page);
        const endPoint = page * 5;
        const startPoint = endPoint - 5;
        setCurrentPagePosts(posts.slice(startPoint, endPoint));
        setActivePage(page);
    };
    if (!author || !currentPagePosts) return (<Loader center size="lg" content="loading" />);

    return (
        <>
            <div className='about-container'>
                <div className="about-parent">
                    <div className="about-child">
                        <img id="pic1" src={author.picture.url} />

                    </div>
                    <div className="about-child">
                        <h4>
                            {author.name},
                        </h4>
                        <h4>
                            {author.title}
                        </h4>
                        <br />
                        <p> {author.biography}</p>

                    </div>
                </div>
                <div><br /> <h4 className='divider'>Articles from the author</h4><hr /></div>
                <div className='article-container'>{
                    currentPagePosts.map((post, id) => (
                        <div key={id} className='articles'>
                            <div id="artcile-image-div">
                                <a href={`/posts/${post.slug}`}>
                                    <img hrer="" id="artcile-image" src={post?.featuredImage?.url} alt="test" />
                                </a>
                            </div>
                            <div id="article-data">

                                <h5 id="article-title">{post.title}</h5>
                                <div id="article-date">{moment(post.date).format('MMM DD, YYYY')}</div>

                                <div id="article-author">
                                    <img id="author-pic2" src={post?.author?.picture?.url} height='30px' width='30px' alt="test" />
                                    <Link id="link" to={`/author/${slug}`}><span id="author">By {post.author.name}</span></Link>
                                </div>

                                <div id="article-excerpt">{post.excerpt}</div>
                                <Button href={`/posts/${post.slug}`} appearance="ghost">Read More.....</Button>
                            </div>

                        </div>
                    ))}
                    <div className='pager'>
                        <Pagination
                            prev
                            last
                            next
                            first
                            size="lg"
                            total={totalCount}
                            limit={5}
                            activePage={activePage}
                            onChangePage={handleNext}
                        />
                    </div>
                </div>


            </div>
            <div className='foot'>
                <Footer />
            </div>
        </>
    )
}

export default Author