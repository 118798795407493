import React from 'react';
import { request } from 'graphql-request';
import Footer from './Footer';
import './Styles/Certifications.css'
import { Loader } from 'rsuite';
import { Pagination } from 'rsuite';

const Certifications = () => {
    const [activePage, setActivePage] = React.useState(null);
    const [totalCount, setTotalCount] = React.useState(null);
    const [currentPageCerts, setCurrentPageCerts] = React.useState(null);
    const [currentCerts, setCurrentCerts] = React.useState(null);
    React.useEffect(() => {
        const fetchCerts = async () => {
            const { certificates } = await request(
                'https://api-ap-south-1.graphcms.com/v2/cl1ipxxok67rl01z61a4r4ndg/master',
                `
                {
                    certificates(orderBy: createdAt_ASC)  {
                        id
                        title
                        link
                        description
                        image {
                            url
                        }
                    }
                }`
            );

            setCurrentCerts(certificates);
            setCurrentPageCerts(certificates?.slice(0, 9));
            setTotalCount(certificates.length)
            setActivePage(1)
        };
        fetchCerts();
    }, []);

    const handleNext = (page) => {
        const endPoint = page * 9;
        const startPoint = endPoint - 9;
        setCurrentPageCerts(currentCerts.slice(startPoint, endPoint));
        setActivePage(page);
    };
    if (!currentPageCerts) return (<Loader center size="lg" content="loading" />);
    return (
        <><div className="card-certs">
            {currentPageCerts.map((val, id) => (
                <div key={id} className="card-child-1-certs" onClick={() => { window.open(val.link, "_blank"); }}>
                    <div className="image-container-certs"><img className='card-image-certs' src={val.image.url} />
                    </div>
                    <div className="card-title-certs">{val.title}</div>
                </div>
            ))}


        </div>
            <div className='pager'>
                <Pagination
                    prev
                    last
                    next
                    first
                    size="lg"
                    total={totalCount}
                    limit={9}
                    activePage={activePage}
                    onChangePage={handleNext}
                />
            </div>
            <Footer /></>
    )
}

export default Certifications