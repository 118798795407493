import React from 'react';
import pic1 from '../data/images/1658604149585 (1).jpg';
import './Styles/about.css';
import Footer from './Footer';
import ContactInfo from './ContactInfo';

const About = () => {
    return (
        <>
            <div className='about-container'>

                <div className='section-1'>
                    <h2 className='divider-3'>About Us</h2>
                    <p>
                        Welcome to FP&A Professionals, your go-to resource for FP&A.
                        Our mission is to help 1 million finance professionals get started on their FP&A journey by providing them with the knowledge and tools they need to succeed.
                    </p>
                    <p>
                        Our founder, CA Asif Masani, is an author and a seasoned finance professional with 10 plus years of experience in FP&A.
                        He created FP&A Professionals dot com to share his knowledge and help young finance professionals avoid the mistakes he made in his own career.
                    </p>
                    <p>
                        Asif provides tips, tricks, and study guides to help you get your career in FP&A off to the right start with confidence.
                    </p>
                    <p>
                        If you're a young finance professional looking to make the leap to FP&A, this is the place for you.
                        We offer FP&A relevant content, career advice, and answer your questions about FP&A.
                        Our articles, videos and courses cover a wide range of topics, from basic to advanced FP&A topics like Budgeting, Forecasting, Management Reporting, Business Acumen, Presentations, Data Storytelling, Finance Business Partnering and Business Strategy.
                    </p>
                    <p>
                        Join us on our FP&A journey by registering on our website and joining the hundreds of finance professionals who are taking their career to the next level by learning from our experiences.
                    </p>
                    <p>
                        Whether you're just getting started in FP&A or looking to advance your career, we're here to help.
                    </p>
                </div>
                <div className="about-parent">
                    <div className="about-child">
                        <img id="pic1" src={pic1} />

                    </div>
                    <div className="about-child">
                        <h2>
                            CA Asif Masani,
                        </h2>
                        <h3>
                            FP&A Lead for India and APAC at Coursera
                        </h3>
                        <br />
                        <p>Asif Masani is an FP&A professional with 12+ years of Finance and FP&A experience. He has worked across the banking, pharma and Edtech industries with organizations like EY, Citibank and Pfizer. Presently, he leads the FP&A function for APAC and India at Coursera. He is a Chartered Accountant by qualification with a flair for driving strategy, decision-making and value creation for organizations.</p>
                        <br />
                        <p> Asif is one of the leading thought leaders in the field of FP&A, Strategy and leadership. He is a regular contributor at FP&A Trends and FP&A Professionals. His goal is to help 1 million students, professionals and executives develop essential FP&A skills.</p>
                    </div>
                </div>
                <div>
                    <h2>Contact Info</h2>
                    <ContactInfo />
                </div>
            </div>
            <div className='foot'>
                <Footer />
            </div>
        </>

    )
}

export default About;