// import React from "react";
// import {
//     Box,
//     Container,
//     Row,
//     Column,
//     FooterLink,
//     Heading,
// } from "./FooterStyles";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'

// const Footer = () => {
//     return (
//         <Box>
//             <h2 style={{
//                 color: "#004d99",
//                 textAlign: "center",
//                 marginTop: "-50px"
//             }}>
//                 FP&A Professionals:  The essential guide to your FP&A journey
//             </h2>
//             <Container>
//                 <Row>
//                     <Column>
//                         <FooterLink href="/about"> <Heading> About</Heading></FooterLink>
//                     </Column>
//                     <Column>
//                         <Heading>Content</Heading>
//                         <FooterLink href="/articles">Articles</FooterLink>
//                         <FooterLink href="/videos">Videos</FooterLink>
//                         <FooterLink href="/books">Books</FooterLink>
//                     </Column>
//                     <Column>
//                         <Heading>Services</Heading>
//                         <FooterLink href="https://topmate.io/asif_masani">Schedule 1:1 with Asif</FooterLink>
//                         <FooterLink href="https://sendfox.com/asifmasani">Subscribe to Newsletter</FooterLink>
//                     </Column>
//                     <Column>
//                         <Heading>Social Media</Heading>
//                         <FooterLink href="https://www.youtube.com/user/asifmasani">
//                             <FontAwesomeIcon className="fa-2x yt" icon={brands('youtube')} />
//                         </FooterLink>
//                         <FooterLink href="https://www.linkedin.com/in/asifmasani/">
//                             <FontAwesomeIcon className="fa-2x" icon={brands('linkedIn')} />
//                         </FooterLink>
//                         <FooterLink href="https://twitter.com/asif_masani?lang=en">
//                             <FontAwesomeIcon className="fa-2x" icon={brands('twitter')} />
//                         </FooterLink>
//                         <FooterLink href="https://www.facebook.com/asif.masani">
//                             <FontAwesomeIcon className="fa-2x" icon={brands('facebook')} />
//                         </FooterLink>

//                     </Column>
//                 </Row>
//             </Container>
//         </Box>
//     );
// };
// export default Footer;

import React from "react";
import logo from '../data/images/bgr.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import './Styles/footer.css'

function Footer() {
  return (
    <><footer>
      <div className="section">
        <div> <img width="300px" src={logo} /></div>
        <div>
          <a href="https://www.youtube.com/user/asifmasani" target="_blank"><FontAwesomeIcon className="fa-1x yt" icon={brands('youtube')} /> </a>
          <a href="https://www.linkedin.com/in/asifmasani/" target="_blank"><FontAwesomeIcon className="fa-1x" icon={brands('linkedin')} /> </a>
          <a href="https://twitter.com/asif_masani?lang=en" target="_blank"><FontAwesomeIcon className="fa-1x" icon={brands('twitter')} /> </a>
          <a href="https://www.facebook.com/asif.masani" target="_blank"><FontAwesomeIcon className="fa-1x" icon={brands('facebook')} /> </a>
        </div>
      </div>
      <div className="section">
        <h4>
          <a href='/about'>
            <span>About us</span>
          </a>
        </h4>
        <h4>
          <a href='./articles'>
            <span>Articles</span>
          </a>
        </h4>
        <h4>
          <a href='./courses'>
            <span>Courses</span>
          </a>
        </h4>
      </div>
      <div className="section">
        <h4>
          <a href="https://topmate.io/asif_masani" target="_blank">
            <span>Schedule 1:1</span>
          </a>
        </h4>
        <h4>
          <a href="https://creative-thinker-9463.ck.page/764eca1113" target="_blank">
            <span>Subscribe for Newsletter</span>
          </a>
        </h4>
        <h4>
          <a href='./certifictions'>
            <span>Certifications</span>
          </a>
        </h4>
      </div>
      <style jsx>{`
        footer {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          padding: 5px;
          background-color: #f2f2f2;
          color: #333;
          font-size: 14px;
          text-align: center;
        }

        .section {
          margin-right:13rem;
          max-width: 30%;
        }

        h4 {
          margin-bottom: 5px;
          font-size: 16px;
          font-weight: 600;
        }

        p {
          margin: 0;
        }

        @media (max-width: 768px) {
          .section {
            flex-basis: 50%;
            max-width: 50%;
          }
        }
      `}</style>

    </footer>
      <div id="copyright">
        <p class="copyright"> Copyright © 2023 fpnaprofessionals.com. All rights reserved. </p>
      </div></>
  );
}

export default Footer;

